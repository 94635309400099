import countiesData from "../data/counties_data.json";
//getting counties data and decoding them
// Extract county codes from countiesData
//replace county code with name comparing from my counties json file
export const getCountyName = (codes) => {
  // Check if codes is empty or not
  codes = codes.toString();
  if (!codes || codes.trim() === "") {
    return "";
  }

  return codes
    .split(", ")
    .map((code) => {
      code = parseInt(code.trim());
      const county = countiesData.find((c) => c.code === code);
      return county ? county.name : `Unknown County (${code})`;
    })
    .join(", ");
};

// src/pages/approved-user/NewDataRequest.js

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Modal,
  Form,
  ListGroup,
  Spinner,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import countiesData from "../../data/counties_data.json";
import useApiRequest from "../../hooks/useApiRequest";
import { useFetchCategories } from "../../hooks/dataHooks";
import {
  DataRequestReason,
  DSARadioSelect,
  DSAFilePicker,
  FlashMessage,
  DataRequestSummaryModal,
} from "../../components/FormComponents";
import LoadingOverlay from "../../components/layout/components/LoadingOverlay";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";
import useStore from "../../store/store";
import QueryBuilder from "../../components/NewDataRequest/QueryBuilder";
import { getCountyName } from "../../utils/getCountiesFromCodes";

const NewDataRequest = () => {
  const navigate = useNavigate();
  const apiRequest = useApiRequest();
  const { user } = useStore((state) => ({
    user: state.user,
  }));

  const isCountyAccount = user.attributes.tenantType[0] === "county";

  // Extract user's county code if county account
  const userCountyCode = isCountyAccount
    ? parseInt(user.attributes.county[0])
    : null;

  // State hooks for form fields and UI control
  const [requestReason, setRequestReason] = useState("");
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [flashMessage, setFlashMessage] = useState("");
  const [hasSignedDSA, setHasSignedDSA] = useState(true);
  const [metadataData, setMetadataData] = useState({});
  const [datasetsMapData, setDatasetsMapData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  // State hooks for modal visibility
  const [showModal, setShowModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectAllCounties, setSelectAllCounties] = useState(false);
  const [unmetConditions, setUnmetConditions] = useState([]);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  // Ref for QueryBuilder if needed (optional based on implementation)
  const queryBuilderRef = useRef();

  // State for QueryBuilder selections
  const [queryBuilderSelections, setQueryBuilderSelections] = useState({
    payload: null,
    errors: [],
  });

  // Hooks for fetching dropdown options
  const { categoryOptions, fetchCategories } = useFetchCategories();

  useEffect(() => {
    setIsLoadingInitialData(true); // Start loading before all fetches

    const loadInitialData = async () => {
      try {
        await Promise.all([
          fetchCategories(),
          fetchMetadata(),
          fetchDatasetsMap(),
        ]);
        // All fetch operations have completed successfully at this point
      } catch (error) {
        // Handle any errors from the fetch operations here
        console.error("Error loading initial data:", error);
        setFlashMessage("An error occurred loading initial data.");
      } finally {
        setIsLoadingInitialData(false); // End loading after all fetches
      }
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures this runs only once on mount

  useEffect(() => {
    if (isCountyAccount) {
      const foundCounty = countiesData.find((c) => c.code === userCountyCode);
      if (foundCounty) {
        setSelectedCounties([foundCounty.code]); // Preselect the county
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountyAccount, userCountyCode]);

  // Functions for modal handlers
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleTermsAccept = (accepted) => {
    setTermsAccepted(accepted);
  };

  /**
   * Function to fetch /hive/metadata
   */
  const fetchMetadata = async () => {
    try {
      const response = await apiRequest({
        url: "/hive/metadata",
        method: "GET",
        shouldCache: true,
      });
      const data = await response.json();
      if (response.ok) {
        setMetadataData(data);
      } else {
        console.error("HTTP error! Status:", response.status);
        console.error("Response Data:", data);
        setFlashMessage("Failed to fetch metadata.");
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      setFlashMessage("An error occurred fetching metadata.");
    }
  };

  /**
   * Function to fetch /hive/datasets-map
   */
  const fetchDatasetsMap = async () => {
    try {
      const response = await apiRequest({
        url: "/hive/datasets-map",
        method: "GET",
        shouldCache: true,
      });
      const data = await response.json();
      if (response.ok) {
        setDatasetsMapData(data);
      } else {
        console.error("HTTP error! Status:", response.status);
        console.error("Response Data:", data);
        setFlashMessage("Failed to fetch datasets map.");
      }
    } catch (error) {
      console.error("An error occurred fetching Datasets map.", error.message);
      setFlashMessage("An error occurred fetching Datasets map.");
    }
  };

  const handleSelectAllCountiesChange = (e) => {
    setSelectAllCounties(e.target.checked);
    if (e.target.checked) {
      setSelectedCounties(countiesData.map((county) => county.code));
    } else {
      setSelectedCounties([]);
    }
  };

  const handleCountyChange = (e) => {
    let value = parseInt(e.target.value);
    setSelectedCounties((current) =>
      current.includes(value)
        ? current.filter((code) => code !== value)
        : [...current, value],
    );
  };

  /**
   * Handler to generate and show the review modal
   */
  const validateAndShowSummary = () => {
    setHasAttemptedSubmit(true);

    const { payload, errors } = queryBuilderSelections;

    if (errors.length > 0 || !payload) {
      // Handle errors if any
      setFlashMessage(
        "Please address the highlighted issues before proceeding.",
      );
      return;
    }

    // Since the button is disabled when there are unmetConditions,
    // we can safely assume all conditions are met here.
    // However, for added safety, we can perform a final check.

    if (unmetConditions.length === 0) {
      setShowSummaryModal(true);
    } else {
      // This block should not be reachable as the button is disabled,
      // but it's good practice to handle it.
      setFlashMessage(
        "Please address the highlighted issues before proceeding.",
      );
    }
  };

  /**
   * Handler to confirm and submit the data
   */
  const handleConfirmSubmit = () => {
    setShowSummaryModal(false);
    handleDataSubmit();
  };

  /**
   * Handler to submit the data to the backend
   */
  const handleDataSubmit = async () => {
    // Create a new FormData object
    const formData = new FormData();

    const { payload, errors } = queryBuilderSelections;

    if (errors.length > 0 || !payload) {
      setFlashMessage(
        "Please address the highlighted issues before proceeding.",
      );
      return;
    }

    // Ensure categories are selected and have fields
    if (!payload || payload.length === 0) {
      setFlashMessage("Error: At least one category must be selected.");
      return;
    }

    // Append data to the FormData object
    formData.append("queryPayload", JSON.stringify(payload));

    formData.append("reason", requestReason);

    // Handle the file input for Data Sharing Agreement
    if (!isCountyAccount) {
      const fileInput = document.getElementById("dsa");
      if (hasSignedDSA) {
        if (fileInput.files.length > 0) {
          const file = fileInput.files[0];
          if (file.size > 10485760) {
            // 10 MB in bytes
            setFlashMessage("File size should not exceed 10MB.");
            return;
          }
          formData.append("dsa", file, file.name);
        } else {
          setFlashMessage("Error: Please attach the Data Sharing Agreement.");
          return;
        }
      } else {
        setFlashMessage("Error: You need to have an approved DSA.");
        return;
      }
    }

    // Append counties only if not a county account (since it's auto-added)
    if (!isCountyAccount) {
      formData.append("county", selectedCounties.join(","));
    }

    try {
      setIsSubmitting(true);
      const response = await apiRequest({
        url: "/data-requests/beta-create",
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setIsSubmitting(false);
        setFlashMessage(
          "Successfully Sent Data Request.\nNavigating you to your data requests.",
        );
        setTimeout(() => {
          navigate("/u/data-requests");
        }, 2000);
      } else {
        setIsSubmitting(false);
        const errorMessage = `HTTP error! Status: ${response.status}`;
        console.error(errorMessage);
        const responseData = await response.json();
        console.error("Response Data:", responseData);
        setFlashMessage("Request failed.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("An error occurred:", error.message);
      setFlashMessage("An error occurred.");
    }
  };

  /**
   * Handler to receive selections from QueryBuilder
   */
  const handleQueryBuilderSelectionsChange = ({ payload, errors }) => {
    setQueryBuilderSelections({ payload, errors });
  };

  // Create a mapping from field names to human-readable labels
  const createFieldLabelMap = () => {
    const fieldMap = {};
    queryBuilderSelections.payload?.forEach((cat) => {
      const category = categoryOptions.find(
        (option) => option.value === cat.categoryId,
      );
      if (category && category.fieldsData) {
        category.fieldsData.forEach((field) => {
          fieldMap[field.name] = field.label || field.name;
        });
      }
    });
    return fieldMap;
  };

  // Create a mapping from county codes to names
  const countyCodeToName = {};
  countiesData.forEach((county) => {
    countyCodeToName[county.code] = county.name;
  });

  useEffect(() => {
    const conditions = [];

    const { payload, errors } = queryBuilderSelections;

    // Validate QueryBuilder selections
    if (!payload || payload.length === 0) {
      conditions.push("At least one category must be selected.");
    } else {
      // Further validations based on categories' selections
      payload.forEach((cat) => {
        if (!cat.fields || cat.fields.length === 0) {
          conditions.push(
            `At least one field must be selected for category: ${cat.categoryId}.`,
          );
        }
        // Add more validations as needed
      });
    }

    // Existing validations
    if (requestReason.trim().length === 0) {
      conditions.push("A reason for the request must be provided.");
    }
    if (!isCountyAccount && selectedCounties.length === 0) {
      conditions.push("At least one county must be selected.");
    }
    if (!isCountyAccount && (hasSignedDSA === null || !hasSignedDSA)) {
      conditions.push("You must have an approved Data Sharing Agreement.");
    }
    if (
      !isCountyAccount &&
      document.getElementById("dsa")?.files?.length === 0 &&
      hasSignedDSA
    ) {
      conditions.push(
        "You must attach the Data Sharing Agreement PDF document.",
      );
    }
    if (!termsAccepted) {
      conditions.push("You must accept the terms and conditions.");
    }

    setUnmetConditions(conditions);
  }, [
    queryBuilderSelections,
    requestReason,
    selectedCounties,
    hasSignedDSA,
    termsAccepted,
    isCountyAccount,
    categoryOptions,
  ]);

  return (
    <Container fluid>
      {isLoadingInitialData && (
        <LoadingOverlay loading={isLoadingInitialData} />
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="p-0">
          <Card>
            <Card.Header className="py-3">
              <h6 className="m-0 font-weight-bold">
                Create and submit a new dataset request
              </h6>
            </Card.Header>
            <Card.Body className="p-3 p-lg-4" style={{ position: "relative" }}>
              {isSubmitting && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1050, // Use a high z-index to ensure it covers other elements
                    backgroundColor: "rgba(255, 255, 255, 0.75)", // Semi-transparent overlay
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
              <p>
                <b>Welcome to the Data Access Portal. </b>The registries offer
                aggregated datasets encompassing a wide array of categories.
                Please request the data you need below.
              </p>
              <br />
              <form>
                {/* Integrate QueryBuilder */}
                <QueryBuilder
                  ref={queryBuilderRef}
                  isCountyAccount={isCountyAccount}
                  userCountyName={getCountyName(userCountyCode)}
                  onSelectionsChange={handleQueryBuilderSelectionsChange}
                />

                {/* Display Selected Counties if not a county account */}
                {!isCountyAccount && (
                  <>
                    <h6 className="mt-4">
                      Select Counties: (<small>Required</small>)
                    </h6>
                    <Button
                      variant={
                        selectedCounties.length === 0 ? "warning" : "primary"
                      }
                      onClick={handleShowModal}
                    >
                      Select Counties
                    </Button>
                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Select Counties</Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{
                          maxHeight: "80vh",
                          overflowY: "auto",
                        }}
                      >
                        <Form>
                          <Form.Check
                            type="checkbox"
                            id="select-all-counties"
                            label="Select All"
                            onChange={handleSelectAllCountiesChange}
                            checked={
                              selectedCounties.length === countiesData.length
                            }
                            className="mb-2"
                            style={{ fontWeight: "bold", color: "#347925" }}
                          />
                          {countiesData
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((county) => (
                              <Form.Check
                                type="checkbox"
                                id={`county-${county.code}`}
                                label={county.name}
                                key={county.code}
                                value={county.code}
                                checked={selectedCounties.includes(county.code)}
                                onChange={handleCountyChange}
                              />
                            ))}
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModal}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    {selectedCounties.length > 0 && (
                      <ListGroup className="mt-2">
                        {selectedCounties.map((code) => {
                          const county = countiesData.find(
                            (c) => c.code === code,
                          );
                          return (
                            <ListGroup.Item
                              key={code}
                              className="d-flex justify-content-between align-items-center"
                            >
                              {county ? county.name : `County Code: ${code}`}
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  setSelectedCounties((current) =>
                                    current.filter((c) => c !== code),
                                  )
                                }
                              >
                                Remove
                              </Button>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    )}
                  </>
                )}

                {/* Reason for Data Request */}
                <div className="mb-3"></div>
                <DataRequestReason
                  value={requestReason}
                  onChange={setRequestReason}
                />

                {/* Data Sharing Agreement */}
                {!isCountyAccount && (
                  <>
                    <DSARadioSelect
                      hasSignedDSA={hasSignedDSA}
                      onChange={setHasSignedDSA}
                    />
                    {hasSignedDSA && <DSAFilePicker />}
                  </>
                )}

                {/* Terms and Conditions */}
                <Button
                  variant={!termsAccepted ? "warning" : "primary"}
                  onClick={() => setShowTermsModal(true)}
                  className="mb-2"
                >
                  <b>View Terms and Conditions</b>
                </Button>
                <br />
                <TermsAndConditionsModal
                  show={showTermsModal}
                  onHide={() => setShowTermsModal(false)}
                  onChanged={handleTermsAccept}
                />
                {!termsAccepted && (
                  <Alert variant="danger">
                    Please read and acknowledge the terms and conditions by
                    ticking the checkbox at the bottom.
                  </Alert>
                )}

                {/* Flash Messages */}
                <FlashMessage
                  variant={
                    flashMessage?.toLowerCase().startsWith("error")
                      ? "danger"
                      : "info"
                  }
                  message={flashMessage}
                />

                {/* Review Modal */}
                <DataRequestSummaryModal
                  show={showSummaryModal}
                  onHide={() => setShowSummaryModal(false)}
                  selections={{
                    queryBuilder: queryBuilderSelections,
                    requestReason,
                    selectedCounties,
                    hasSignedDSA,
                    isCountyAccount,
                    fieldLabels: createFieldLabelMap(),
                    countyCodeToName,
                  }}
                  onSubmit={handleConfirmSubmit}
                />

                {/* Display Unmet Conditions */}
                {unmetConditions.length > 0 && (
                  <Alert variant="warning">
                    <p>
                      <b>Please address the following to submit:</b>
                    </p>
                    <ul>
                      {unmetConditions.map((condition, index) => (
                        <li key={index}>{condition}</li>
                      ))}
                    </ul>
                  </Alert>
                )}

                {/* Submit Button */}
                <Button
                  variant="success"
                  onClick={() => validateAndShowSummary()}
                  disabled={unmetConditions.length > 0}
                >
                  Review and Submit Request
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewDataRequest;
